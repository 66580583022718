<template>
  <div class="contents liveInfo" style="position: relative; height: calc(100vh - 50px);">
    <div class="new_title">
      <h1>{{ $t("site-control-contents-sub-live-map") }}</h1>
    </div>
    <div class="tabBox one" style="border: none; width: 22%;margin-right: 20px;">
      <div class="liveMap-detail">
        <div class="liveMap-detail-info" :style="{ 'height': (typeChangeMapShowHeight - 180) + 'px' }">
          <h2>상세정보</h2>
          <div class="liveMap-detail-info-header">
            <span>{{ $t("livemap-data-way-waypointId") }}</span>
            <h2>{{ missionInfo.missionId }}</h2>
          </div>
          <div class="liveMap-detail-info-data">
            <h4>{{ $t("livemap-data-way-startdate") }}</h4>
            <h2>{{ moment(startDate).format("YYYY.MM.DD HH:mm") }}</h2>
          </div>
          <ul>
            <li>
              <span>{{ $t("device-data-manager-dept-name") }}</span>
              <h4>{{ missionInfo.userDepartmentName }}</h4>
            </li>
            <li>
              <span>{{ $t("device-data-manager-operator") }}</span>
              <h4>{{ missionInfo.userName }}</h4>
            </li>
            <li>
              <span>{{ $t("device-data-device-name") }}</span>
              <h4>{{ deviceName }}</h4>
            </li>
            <li>
              <span>{{ $t("device-data-model-name") }}</span>
              <h4>{{ deviceModelName }}</h4>
            </li>
          </ul>
        </div>
        <button class="point medium"
          style="width: 100%;height:40px; background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;"
          @click="backToLiveMapList">{{ $t("livemap-data-back-to-list") }}</button>
      </div>
    </div>
    <div class="map" style="width: calc(78% - 45px);height: calc(100% + 50px);position: absolute;right: 0;bottom: 0">
      <!--<LoadingSpinner v-if="isLoading" style="position: absolute; z-index: 20; left: 50%; top: 50%; transform: translate(-50%, -50%);"></LoadingSpinner>-->
      <Map ref="map" style="width : 100%; height: 100%; position: absolute; z-index:1;" :loadMapName="loadMap" />
      <MapButtonComponent ref="buttonMap" />
    </div>
  </div>
</template>

<style>
.liveMap-detail-info>h2 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 25px;
}

.liveMap-detail-info-header {
  margin-bottom: 15px;
}

.liveMap-detail-info-header>h2 {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 28px;
}

.liveMap-detail-info-header>span {
  color: #969696;
  font-size: 1.2rem;
  margin-bottom: 10px;
  display: block;
}

.liveMap-detail-info-data {
  background: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.liveMap-detail-info-data>h4 {
  font-size: 1.2rem;
  color: #969696;
  font-weight: 300;
}

.liveMap-detail-info-data>h2 {
  margin: 0;
  font-size: 1.4rem;
}

.liveMap-detail-info>ul>li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.liveMap-detail-info>ul>li>span {
  width: 100px;
  background: #eee;
  border-radius: 10px;
  text-align: center;
  color: #969696;
  padding: 3px;
  font-size: 1.2rem;
}

.liveMap-detail-info>ul>li>h4 {
  font-size: 1.4rem;
  font-weight: 400;
}
</style>

<script>
import moment from "moment";
import { fetchMissionInfo } from "@/api/mission";

import { fetchEvent } from "@/api/event";
import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import { fetchLocation } from "@/api/location";
import { getUrlLiveMapThumbnail } from "@/api/contents";
import { mapState } from "vuex";
import { getDefaultMapZoomLevel } from "@/util/common";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";

export default {
  components: { Map, MapButtonComponent },
  props: {
  },
  data() {
    return {
      index: 0,
      moment: moment,
      missionInfo: {},
      waypointLocationList: [],
      mapWidth: 0,
      offset: -1,
      typeChangeMapShowHeight: 0,
      loadMap: 'kakao',
      keywordType: "Red",

      eventId: null,
      waypointId: null,
      refId: null,
      contentsId: null,
      deviceModelName: null,
      deviceName: null,
      startDate: null
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 5 });

    this.mapWidth = window.innerWidth - 322;
    this.typeChangeMapShowHeight = this.mapWidth * 0.5625;
    window.addEventListener('resize', this.handleResize);

    this.eventId = this.$route.query.eventId;
    this.missionId = this.$route.query.missionId;
    this.refId = this.$route.query.refId;
    this.contentsId = this.$route.query.contentsId;
    this.deviceModelName = this.$route.query.deviceModelName;
    this.deviceName = this.$route.query.deviceName;
    this.startDate = this.$route.query.startDate;
  },
  watch: {
    currentLang(value) {
      this.initMapData(value);
    },
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  mounted() {
    this.getEvent();
    this.getMissionInfo();
    this.initButtonMap()

    this.interval = setInterval(() => {
      this.getLocation();
      // this.getLocationThermal();
    }, 2000);
  },
  methods: {
    initMapData(value) {
      if (value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function () {
        this.onMapCenter();
      })
    },
    onMapCenter() {
      if (this.eventInfo == null) {
        return;
      }
      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      } else {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      }
    },
    initButtonMap() {
      if (this.$refs.buttonMap) {
        this.$refs.buttonMap.showMapTypeChange();
      } else {
        console.error('buttonMap reference not found');
      }
    },

    getEvent() {
      if (this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.initMapData(this.currentLang);
        }
      });
    },
    getMissionInfo() {
      fetchMissionInfo(this.missionId).then((res)=> {
        if (res.data.result == 0) {
          this.missionInfo = res.data.data;
        }
      })
    },
    getLocation() {
      // let i = 0;
      if (this.refId == null) {
        return;
      }
      let params = {
        refId: this.refId,
        eventId: this.eventId,
        contentsId: this.contentsId,
        isThermal: false,
        offsetIndex: this.offset,
      };

      fetchLocation(params).then((res) => {
        var content = res.data.data.content;
        let sort = content.sort((a, b) => { return a.index - b.index })
        if (sort.length > 0) {
          this.offset = sort[sort.length - 1].index;
          let mapDataType = MapDataType.ID_LIVE_MAP;
          this.$refs.map.onLngLatToMoveCenter(sort[0].lng, sort[0].lat)
          sort.forEach((item) => {
            let mapMarkerData = new MapMarkerData();
            mapMarkerData.id = item.index;
            mapMarkerData.lng = item.lng;
            mapMarkerData.lat = item.lat;
            mapMarkerData.rotate = item.azimuth;
            mapMarkerData.thumbnail = getUrlLiveMapThumbnail(item.liveMapFileId);
            mapMarkerData.liveMapBounds = {
              leftBottom: { lat: item.latLeftBottom, lng: item.lngLeftBottom },
              rightTop: { lat: item.latRightTop, lng: item.lngRightTop }
            }
            if (this.$refs.map.onIsMarker(mapDataType, item.index)) {
              this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
            } else {
              this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
            }
          })
          //http://localhost:9000/api/v1/thumbnail/file/FL-23b7556b-ebaf-4571-aee2-51c5d513b86
        }
      });
    },
    getLocationThermal() {
      // let i = 0;
      if (this.refId == null) {
        return;
      }
      let params = {
        refId: this.refId,
        eventId: this.eventId,
        contentsId: this.contentsId,
        isThermal: true,
        offsetIndex: this.offset,
      };

      fetchLocation(params).then((res) => {
        var content = res.data.data.content;
        let sort = content.sort((a, b) => { return a.index - b.index })
        if (sort.length > 0) {
          this.offset = sort[sort.length - 1].index;
          let mapDataType = MapDataType.ID_LIVE_MAP_THERMAL;
          this.$refs.map.onLngLatToMoveCenter(sort[0].lng, sort[0].lat)
          sort.forEach((item) => {
            let mapMarkerData = new MapMarkerData();
            mapMarkerData.id = item.index;
            mapMarkerData.lng = item.lng;
            mapMarkerData.lat = item.lat;
            mapMarkerData.rotate = item.azimuth;
            mapMarkerData.thumbnail = getUrlLiveMapThumbnail(item.liveMapFileId);
            mapMarkerData.isShow = false;
            mapMarkerData.liveMapBounds = {
              leftBottom: { lat: item.latLeftBottom, lng: item.lngLeftBottom },
              rightTop: { lat: item.latRightTop, lng: item.lngRightTop }
            }
            if (this.$refs.map.onIsMarker(mapDataType, item.index)) {
              this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
            } else {
              this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
            }
          })
        }
      });
    },
    createMapLine(id, locationGroup) {
      let mapDataType = MapDataType.ID_LIVE
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if (this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    handleResize() {
      this.mapWidth = window.innerWidth - 322;
      this.typeChangeMapShowHeight = this.mapWidth * 0.5625;
    },
    handleTab(id) {
      this.visible = id;
    },
    backToLiveMapList() {
      this.$router.go(-1)
      //this.$emit("back-list")
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
  },
};
</script>
